import React, { ReactElement, useEffect, useState } from 'react';
import { IBlock, IPageData, IPageProps } from '@Types/ContentfulTypes';
import { graphql } from 'gatsby';
import { usePageTheme, useApp } from '@Context/AppContext';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';
import ContentfulModule from '@Src/components/ContentfulModule';
import SEO from '@Components/SEO';
import '@GraphQL/fragments';
import Signup from '@Src/components/Signup';
import LinkForm from '@Src/components/Signup/Form/link';
import PitchForm from "@Components/Pitch/Form";
import Text from '@Src/components/Text';

export interface Props extends IPageProps {
    data: {
        contentfulPageTypePitch: IPageData;
    };
}

const SignupPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
    //const { title, seoSettings, content } = data.contentfulPageTypePitch;
    const title = '', seoSettings = 'label pitch', content = ''
    const { setTheme: setPageTheme } = usePageTheme();
    const { mode, pageType, setPageType, setPageTitle } = useApp();
    const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);

    useEffect(() => {
        if (pageType === 'index' && transitionStatus == 'exiting') {
            setLocalTransStatus(transitionStatus + 'Index');
        } else {
            setLocalTransStatus(transitionStatus);
        }

        if (transitionStatus == 'entering' || transitionStatus == 'entered') {
            // setPageTheme(mode == 'yellow' ? mode : mode == 'day' ? 'yellow' : 'night');
            console.log("mode", mode)
            setPageTitle(title);
            setPageType(pageContext.type);
        }
    }, [transitionStatus]);

    useEffect(() => {
        if (transitionStatus == 'entered') {
            setPageTheme(mode == 'day' ? 'yellow' : 'dark');
        }
    }, [mode]);

    return (
        <ContentLayout transitionState={localTransStatus} fullWidth type={'pitch'}>
            <SEO
                location={location.pathname}
                title={seoSettings?.title || title}
                description={seoSettings?.description}
                meta={seoSettings?.metaTags}
                image={seoSettings?.image}
            />
            {content &&
                content.map(
                    (module: IBlock, index: number): ReactElement => (
                        <ContentfulModule key={module.id} type={module.type} data={module} />
                    )
                )}
            <Text theme={mode} heading01={"Thank you for your pitch!"} />
        </ContentLayout>
    );
};

export default SignupPage;